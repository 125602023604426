/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTableCells,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import Layout from '../../components/template/Layout';
import EstablishmentCardChoice from '../../components/molecules/List/EstablishmentCardChoice/EstablishmentCardChoice';
import Search from '../../components/molecules/Search/Search';
// hooks
import useAppContext from '../../store/useAppContext';

// api
import { getEstablishmentsList } from '../../services/structures';

// style
import styles from './ChoiceEstablishment.module.css';

// util
import cn from '../../utils/cn';
import Picto from '../../components/atoms/Picto/Picto';
import Table from '../../components/molecules/Table/Table';
import Button from '../../components/atoms/Button/Button';

function ChoiceEstablishment() {
  const [search, setSearch] = useState('');
  const [establishmentsFiltered, setEstablishmentsFiltered] = useState('');
  const [establishments, setEstablishments] = useState();
  const [type, setType] = useState(0);
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const navigate = useNavigate();
  const [getEstablishmentsListQuery, setGetEstablishmentsListQuery] = useState();

  useEffect(() => {
    if (context?.typeList) {
      setType(context?.typeList);
    }
  }, []);

  const handleType = (nb) => {
    setType(nb);
    dispatch({ type: 'SET_TYPE_LIST', payload: nb });
  };

  const handleChoiceEstablishment = (establishment) => {
    const estab = establishment.name
      .replace(/[^a-zA-Z0-9\s&→]/g, '')
      .replace(/\s+/g, '-')
      .replace(/^-+|-+$/g, '')
      .replace(/-+/g, '-');
    dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: establishment });
    navigate(`/sanitaire/${establishment.id}-${estab}/dashboards`);
  };

  const fetchData = async () => {
    try {
      const data = await getEstablishmentsList({ bookletId: context?.choiceBooklet });
      setGetEstablishmentsListQuery(data);
      setEstablishmentsFiltered(data?.config?.params?.bookletId);
      setEstablishments(data?.data?.establishments);
      if (data?.data?.establishments?.length === 1 && context?.user?.role === 'ROLE_USER') {
        handleChoiceEstablishment(data?.data?.establishments[0]);
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching establishments:', error);
    }
  };

  useEffect(() => {
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
    if (context?.Establishment_Sanitaire == null) {
      fetchData();
    } else {
      setEstablishmentsFiltered(1);
      setGetEstablishmentsListQuery(context?.Establishment_Sanitaire);
      setEstablishments(context?.Establishment_Sanitaire?.data?.establishments);
    }
  }, []);

  const handleSearch = (e) => {
    const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.establishments?.filter(
      (establishment) => establishment?.name
        .toString()
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0,
    );
    setEstablishments(establishmentsFilteredBySearch);
    setSearch(e.target.value);
  };

  const Indicator = useMemo(
    () => [
      {
        Header: `${t('choice_establishment.name')}`,
        accessor: 'name',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            <span className={styles.aHref}>{row.original.name}</span>
          </div>
        ),
      },
      {
        Header: `${t('choice_establishment.sanitaryInputsDonePercent')}`,
        accessor: 'indicators.sanitaryinputsdonepercent',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.sanitaryinputsdonepercent === 100 ? (
              <span className={styles.green}>{row.original.indicators.sanitaryinputsdonepercent}</span>
            ) : null}
            {row.original.indicators.sanitaryinputsdonepercent < 100
              && row.original.indicators.sanitaryinputsdonepercent >= 70 ? (
                <span className={styles.tagWarning}>{row.original.indicators.sanitaryinputsdonepercent}</span>
              ) : null}
            {row.original.indicators.sanitaryinputsdonepercent < 70 ? (
              <span className={styles.red}>{row.original.indicators.sanitaryinputsdonepercent}</span>
            ) : null}
          </div>
        ),
      },
      {
        Header: `${t('choice_establishment.lastYearSanitaryInputsDonePercent')}`,
        accessor: 'indicators.lastyearsanitaryinputsdonepercent',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.lastyearsanitaryinputsdonepercent === 100 ? (
              <span className={styles.green}>{row.original.indicators.lastyearsanitaryinputsdonepercent}</span>
            ) : null}
            {row.original.indicators.lastyearsanitaryinputsdonepercent < 100
              && row.original.indicators.lastyearsanitaryinputsdonepercent >= 70 ? (
                <span className={styles.tagWarning}>{row.original.indicators.lastyearsanitaryinputsdonepercent}</span>
              ) : null}
            {row.original.indicators.lastyearsanitaryinputsdonepercent < 70 ? (
              <span className={styles.red}>{row.original.indicators.lastyearsanitaryinputsdonepercent}</span>
            ) : null}
          </div>
        ),
      },
      {
        Header: `${t('choice_establishment.sanitaryinputsdonewithalertpercent')}`,
        accessor: 'indicators.sanitaryinputsdonewithalertpercent',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.sanitaryinputsdonewithalertpercent > 0 ? (
              <span className={styles.red}>{row.original.indicators.sanitaryinputsdonewithalertpercent}</span>
            ) : (
              <span className={styles.green}>{row.original.indicators.sanitaryinputsdonewithalertpercent}</span>
            )}
          </div>
        ),
      },
      {
        Header: `${t('choice_establishment.pneumophilaAnalysisSanitaryPercent')}`,
        accessor: 'indicators.pneumophilaanalysissanitarypercent',
        Cell: ({ row }) => {
          const percent = row.original.indicators?.pneumophilaanalysissanitarypercent;
          let content;
          if (percent === null) {
            content = (
              <span className={styles.gray}>
                {t('choice_establishment.no_analyse_data')}
              </span>
            );
          } else if (percent === 100) {
            content = (
              <span className={styles.green}>
                {percent}
              </span>
            );
          } else if (percent < 100 && percent >= 70) {
            content = (
              <span className={styles.orange}>
                {percent}
              </span>
            );
          } else if (percent < 70) {
            content = (
              <span className={styles.red}>
                {percent}
              </span>
            );
          }
          return (
            <div onClick={() => handleChoiceEstablishment(row.original)}>
              {content}
            </div>
          );
        },
      },
      {
        Header: `${t('choice_establishment.complianceLastSanitaryAnalysisPercent')}`,
        accessor: 'indicators.compliancelastsanitaryanalysispercent',
        Cell: ({ row }) => {
          const percent = row.original.indicators?.compliancelastsanitaryanalysispercent;
          let content;
          if (percent === null) {
            content = <span className={styles.gray}>{t('choice_establishment.no_analyse_data')}</span>;
          } else if (percent === 100) {
            content = <span className={styles.green}>{percent}</span>;
          } else if (percent < 100 && percent >= 70) {
            content = <span className={styles.tagWarning}>{percent}</span>;
          } else if (percent < 70) {
            content = <span className={styles.red}>{percent}</span>;
          }
          return (
            <div onClick={() => handleChoiceEstablishment(row.original)}>
              {content}
            </div>
          );
        },
      },
      {
        Header: `${t('choice_establishment.latesecurityreportscount')}`,
        accessor: 'indicators.latesanitaryreportscount',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.latesanitaryreportscount > 0 ? (
              <span className={styles.red}>{row.original.indicators.latesanitaryreportscount}</span>
            ) : (
              <span className={styles.green}>{row.original.indicators.latesanitaryreportscount}</span>
            )}
          </div>
        ),
      },
    ],
  );

  const bookletHeaderColor = (establishment) => {
    if (establishment?.indicators?.lastsanitarylegionellaanalysispneumophilatype === 'pneumo') {
      return 'red';
    }
    if (establishment?.indicators?.lastsanitarylegionellaanalysispneumophilatype === 'ufc') {
      return 'orange';
    }
    if (establishment?.indicators?.lastsanitarylegionellaanalysispneumophilatype === 'ok') {
      return 'green';
    }
    return null;
  };

  return (
    <Layout
      title={t('choice_establishment.title_page')}
      queryError={
        getEstablishmentsListQuery?.error
      }
    >
      <header className={
        cn(['header',
          styles.headerChoice,
          'shadow-md',
          styles.sanitary,
        ])
}
      >
        <h1 className="titleHomePage">
          <Picto name="etablissementsRoundedBlue" />
          {' '}
          {t('choice_establishment.title_page')}
          {' '}
          <Picto name="etablissementsRoundedBlue" />
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        <div className={styles.searchRow}>
          <Search
            className="mb-20"
            value={search || ''}
            onChange={handleSearch}
          />
        </div>
        <div className={styles.list}>
          <Button
            type="button"
            label={<FontAwesomeIcon icon={faTableCells} />}
            className={cn([styles.filter, type === 0
              ? styles.active
              : ''])}
            onClick={() => handleType(0)}
          />
          <Button
            type="button"
            label={<FontAwesomeIcon icon={faListUl} />}
            className={cn([styles.filter, type === 1
              ? styles.active
              : ''])}
            onClick={() => handleType(1)}
          />
        </div>
        <div>
          {establishmentsFiltered !== 1 && (
            <div className="loaderEtablissement" />
          )}
          {establishmentsFiltered === 1 && (
            <div className={type === 0 && styles.sectionChoiceList}>
              {type === 0 ? (
                establishments?.map((establishment) => (
                  <EstablishmentCardChoice
                    onClick={handleChoiceEstablishment}
                    establishment={establishment}
                    key={establishment?.id}
                    bookletHeaderColor={bookletHeaderColor(establishment)}
                    texte={(
                      <div className={styles.blockSanitaire}>
                        {(establishment?.indicators?.lastsanitarytechnicaldiagdate || establishment?.indicators?.lastsanitaryriskanalysisdate || establishment?.indicators?.nextsanitaryriskanalysisdate) && (
                          <div>
                            <div className={styles.comisionSecu}>
                              {establishment?.indicators?.lastsanitarytechnicaldiagdate && (
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {t('choice_establishment.lastSanitaryTechnicalDiagDate')}
                                  </span>
                                  <br />
                                  <span className={styles.favorable}>
                                    {new Date(establishment?.indicators?.lastsanitarytechnicaldiagdate?.date).toLocaleDateString('fr-FR')}
                                  </span>
                                </div>
                              )}
                              {establishment?.indicators?.lastsanitarytechnicaldiagdate === null && establishment?.indicators?.lastsanitaryriskanalysisdate && (
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {t('choice_establishment.lastSanitaryRiskAnalysisDate')}
                                  </span>
                                  <br />
                                  <span className={styles.favorable}>
                                    {new Date(establishment?.indicators?.lastsanitaryriskanalysisdate?.date).toLocaleDateString('fr-FR')}
                                  </span>
                                </div>
                              )}
                              <div className={styles.vertical} />
                              {establishment?.indicators?.nextsanitaryriskanalysisdate && (
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {t('choice_establishment.nextSanitaryRiskAnalysisDate')}
                                  </span>
                                  <br />
                                  <span className={styles.values}>
                                    {new Date(establishment?.indicators?.nextsanitaryriskanalysisdate?.date).toLocaleDateString('fr-FR')}
                                  </span>
                                </div>
                              )}
                            </div>
                            <hr className={styles.hr} />
                          </div>
                        )}
                        {establishment?.indicators?.sanitaryinputsdonepercent || establishment?.indicators?.lastyearsanitaryinputsdonepercent
                          || establishment?.indicators?.pneumophilaanalysissanitarypercent ? (
                            <div className={styles.texteCard}>
                              <Picto name="contrats" className={styles.picto} />
                              <div className={styles.CardText}>
                                {establishment?.indicators?.sanitaryinputsdonepercent !== null ? (
                                  <li key={establishment.indicators?.sanitaryinputsdonepercent} className={styles.reporttext}>
                                    {establishment?.indicators?.sanitaryinputsdonepercent === 100 ? (
                                      <span className={cn([styles.valueSanitaire, 'tag--success'])}>
                                        {establishment?.indicators?.sanitaryinputsdonepercent}
                                      </span>
                                    ) : null}
                                    {establishment?.indicators?.sanitaryinputsdonepercent < 100
                                      && establishment?.indicators?.sanitaryinputsdonepercent >= 70 ? (
                                        <span className={cn([styles.valueSanitaire, styles.tagWarning])}>
                                          {establishment?.indicators?.sanitaryinputsdonepercent}
                                        </span>
                                      ) : null}
                                    {establishment?.indicators?.sanitaryinputsdonepercent < 70 ? (
                                      <span className={cn([styles.valueSanitaire])}>
                                        {establishment?.indicators?.sanitaryinputsdonepercent}
                                      </span>
                                    ) : null}
                                    <span className={styles.reporttext}>
                                      {t('choice_establishment.sanitaryInputsDonePercent')}
                                    </span>
                                    {' '}
                                  </li>
                                ) : null}
                                {establishment?.indicators?.lastyearsanitaryinputsdonepercent !== null ? (
                                  <li key={establishment.indicators?.lastyearsanitaryinputsdonepercent} className={styles.reporttext}>
                                    {establishment?.indicators?.lastyearsanitaryinputsdonepercent === 100 ? (
                                      <span className={cn([styles.valueSanitaire, 'tag--success'])}>
                                        {establishment?.indicators?.lastyearsanitaryinputsdonepercent}
                                      </span>
                                    ) : null}
                                    {establishment?.indicators?.lastyearsanitaryinputsdonepercent < 100
                                      && establishment?.indicators?.lastyearsanitaryinputsdonepercent >= 70 ? (
                                        <span className={cn([styles.valueSanitaire, styles.tagWarning])}>
                                          {establishment?.indicators?.lastyearsanitaryinputsdonepercent}
                                        </span>
                                      ) : null}
                                    {establishment?.indicators?.lastyearsanitaryinputsdonepercent < 70 ? (
                                      <span className={cn([styles.valueSanitaire])}>
                                        {establishment?.indicators?.lastyearsanitaryinputsdonepercent}
                                      </span>
                                    ) : null}
                                    <span className={styles.reporttext}>
                                      {t('choice_establishment.lastYearSanitaryInputsDonePercent')}
                                    </span>
                                    {' '}
                                  </li>
                                ) : null}
                                {establishment?.indicators?.pneumophilaanalysissanitarypercent !== null ? (
                                  <li key={establishment.indicators?.pneumophilaanalysissanitarypercent} className={styles.reporttext}>
                                    {establishment?.indicators?.pneumophilaanalysissanitarypercent === 100 ? (
                                      <span className={cn([styles.valueSanitaire, 'tag--success'])}>
                                        {establishment?.indicators?.pneumophilaanalysissanitarypercent}
                                      </span>
                                    ) : null}
                                    {establishment?.indicators?.pneumophilaanalysissanitarypercent < 100
                                      && establishment?.indicators?.pneumophilaanalysissanitarypercent >= 70 ? (
                                        <span className={cn([styles.valueSanitaire, styles.tagWarning])}>
                                          {establishment?.indicators?.pneumophilaanalysissanitarypercent}
                                        </span>
                                      ) : null}
                                    {establishment?.indicators?.pneumophilaanalysissanitarypercent < 70 ? (
                                      <span className={cn([styles.valueSanitaire])}>
                                        {establishment?.indicators?.pneumophilaanalysissanitarypercent}
                                      </span>
                                    ) : null}
                                    <span className={styles.reporttext}>
                                      {t('choice_establishment.pneumophilaAnalysisSanitaryPercent')}
                                    </span>
                                    {' '}
                                  </li>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        {establishment?.indicators?.pneumophilaanalysissanitarypercent === null || establishment?.indicators?.complianceLastSanitaryAnalysisPercent === null ? (
                          <div className={styles.texteCard}>
                            <Picto name="contrats" className={styles.picto} />
                            <div className={styles.CardText}>
                              <li key={establishment.indicators?.sanitaryinputsdonepercent} className={styles.reporttext}>
                                <span className={cn([styles.valueSanitaire])}>
                                  {t('choice_establishment.no_analyse_data')}
                                </span>
                              </li>
                            </div>
                          </div>
                        ) : null}
                        <div>
                          {' '}
                        </div>
                        {establishment?.indicators?.latesanitaryreportscount !== null && establishment?.indicators?.latesanitaryreportscount >= 0 ? (
                          <div className={styles.texteCard}>
                            <Picto name="inputs" className={styles.picto} />
                            <div className={styles.CardText}>
                              {establishment?.indicators?.latesanitaryreportscount ? (
                                <li key={establishment?.indicators?.latesanitaryreportscount} className={styles.reporttext}>
                                  <span className={styles.valueSanitaire}>
                                    {establishment?.indicators?.latesanitaryreportscount}
                                    {' '}
                                  </span>
                                  <span className={styles.reporttext}>
                                    {' '}
                                    {t('choice_establishment.latesecurityreportscount')}
                                  </span>
                                </li>
                              ) : null}
                              {establishment?.indicators?.latesanitaryreportscount === 0 ? (
                                <li key={establishment?.indicators?.latesanitaryreportscount} className={styles.nolatereporttext}>
                                  <span className={styles.reporttext}>
                                    {t('choice_establishment.nolatesecurityreports')}
                                  </span>
                                </li>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  />
                )) || ''
              ) : (
                <div className={styles.table}>
                  <Table
                    columns={Indicator}
                    isLoading={establishments?.isLoading}
                    data={establishments || []}
                    hasSort
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default ChoiceEstablishment;
