/* eslint-disable max-len */
/* eslint-enable  no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTableCells,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import Layout from '../../components/template/Layout';
import EstablishmentCardChoice from '../../components/molecules/List/EstablishmentCardChoice/EstablishmentCardChoice';
import Search from '../../components/molecules/Search/Search';
import Button from '../../components/atoms/Button/Button';
// hooks
import useAppContext from '../../store/useAppContext';

// infrastructures
import { getEstablishmentsList } from '../../services/structures';

// style
import styles from './ChoiceEstablishment.module.css';
import { formatDateToUser } from '../../utils/dates';

// util
import cn from '../../utils/cn';
import Picto from '../../components/atoms/Picto/Picto';
import Table from '../../components/molecules/Table/Table';

function ChoiceEstablishment() {
  const [search, setSearch] = useState('');
  const [establishmentsFiltered, setEstablishmentsFiltered] = useState('');
  const [establishments, setEstablishments] = useState([]);
  const [buildingId, setBuildingId] = useState(2);
  const [type, setType] = useState(0);
  const { t } = useTranslation();
  const [context, dispatch] = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (context?.typeList) {
      setType(context?.typeList);
    }
  }, []);

  const handleType = (nb) => {
    setType(nb);
    dispatch({ type: 'SET_TYPE_LIST', payload: nb });
  };

  const handleChoiceEstablishment = (establishment) => {
    const estab = establishment.name
      .replace(/[^a-zA-Z0-9\s&→]/g, '')
      .replace(/\s+/g, '-')
      .replace(/^-+|-+$/g, '')
      .replace(/-+/g, '-');
    dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: establishment });
    navigate(`/securite/${establishment.id}-${estab}/dashboards`);
  };

  const getEstablishmentsListQuery = useQuery('building-template', () => getEstablishmentsList({
    bookletId: 2,
  }), {
    onSuccess: (data) => {
      setEstablishmentsFiltered(data?.config?.params?.bookletId);
      setEstablishments(data?.data?.establishments);
      if (data?.data?.establishments?.length === 1 && context?.user?.role === 'ROLE_USER') {
        handleChoiceEstablishment(data?.data?.establishments[0]);
      }
    },
  });

  useEffect(() => {
    if (context?.choiceEstablishment !== null) {
      dispatch({ type: 'CHOICE_ESTABLISHMENT', payload: null });
    }
    if (!context?.Establishment_Securite) {
      // fetchData();
    } else {
      setEstablishmentsFiltered(2);
    }
  }, []);

  const handleSearch = (e) => {
    const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.data?.establishments?.filter(
      (establishment) => establishment?.name
        .toString()
        .toLowerCase()
        .indexOf(e.target.value.toLowerCase()) >= 0,
    );
    setEstablishments(establishmentsFilteredBySearch);
    setSearch(e.target.value);
  };

  const Indicator = useMemo(
    () => [
      {
        Header: `${t('choice_establishment.name')}`,
        accessor: 'name',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            <span className={styles.aHref}>{row.original.name}</span>
          </div>
        ),
      },
      {
        Header: `${t('indicateur.totalObservationsToLiftCount')}`,
        accessor: 'indicators.totalsecurityobservationstoliftcount',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.totalsecurityobservationstoliftcount > 0 ? (
              <span className={styles.red}>{row.original.indicators.totalsecurityobservationstoliftcount}</span>
            ) : (
              <span className={styles.green}>{row.original.indicators.totalsecurityobservationstoliftcount}</span>
            )}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.prescriptionsSecurityToLiftCount')}`,
        accessor: 'indicators.prescriptionssecuritytoliftcount',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.prescriptionssecuritytoliftcount > 0 ? (
              <span className={styles.red}>{row.original.indicators.prescriptionssecuritytoliftcount}</span>
            ) : (
              <span className={styles.green}>{row.original.indicators.prescriptionssecuritytoliftcount}</span>
            )}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.recordKeepingRate2')}`,
        accessor: 'indicators.securityrecordkeepingrate2',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.securityrecordkeepingrate2 === 100 ? (
              <span className={styles.green}>{row.original.indicators.securityrecordkeepingrate2}</span>
            ) : null}
            {row.original.indicators.securityrecordkeepingrate2 < 100
              && row.original.indicators.securityrecordkeepingrate2 >= 70 ? (
                <span className={styles.tagWarning}>{row.original.indicators.securityrecordkeepingrate2}</span>
              ) : null}
            {row.original.indicators.securityrecordkeepingrate2 < 70 ? (
              <span className={styles.red}>{row.original.indicators.securityrecordkeepingrate2}</span>
            ) : null}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.tableLateReportCount')}`,
        accessor: 'indicators.latesecurityreportscount',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.latesecurityreportscount > 0 ? (
              <span className={styles.red}>{row.original.indicators.latesecurityreportscount}</span>
            ) : (
              <span className={styles.green}>{row.original.indicators.latesecurityreportscount}</span>
            )}
          </div>
        ),
      },
      {
        Header: `${t('indicateur.nextSecurityCommitteeDate')}`,
        accessor: 'indicators',
        Cell: ({ row }) => (
          <div onClick={() => handleChoiceEstablishment(row.original)}>
            {row.original.indicators.nextsecuritycommitteedate ? (
              <div>
                {row.original.indicators.lastsecuritycommitteenotice === 1 && (
                  <span className={styles.green}>{new Date(row.original.indicators?.nextsecuritycommitteedate?.date).toLocaleDateString()}</span>
                )}
                {row.original.indicators.lastsecuritycommitteenotice === 0 && (
                  <span className={styles.red}>{new Date(row.original.indicators?.nextsecuritycommitteedate?.date).toLocaleDateString()}</span>
                )}
              </div>
            ) : (
              <span>{' '}</span>
            )}
          </div>
        ),
      },
    ],
  );

  const avis = [
    {
      label: 'Tous',
      value: 2,
    },
    {
      label: 'Avis favorables',
      value: 1,
    },
    {
      label: 'Avis défavorables',
      value: 0,
    },
  ];

  const handleBuilding = (id) => {
    setBuildingId(id);
    if (id === 0) {
      const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.data?.establishments?.filter(
        (establishment) => establishment?.indicators?.lastsecuritycommitteenotice === 0,
      );
      setEstablishments(establishmentsFilteredBySearch);
    } else if (id === 1) {
      const establishmentsFilteredBySearch = getEstablishmentsListQuery?.data?.data?.establishments?.filter(
        (establishment) => establishment?.indicators?.lastsecuritycommitteenotice === 1,
      );
      setEstablishments(establishmentsFilteredBySearch);
    } else {
      setEstablishments(getEstablishmentsListQuery?.data?.data?.establishments);
    }
  };

  const bookletHeaderColor = (establishment) => {
    if (establishment?.indicators?.lastsecuritycommitteenotice === 1) {
      return 'green';
    }
    if (establishment?.indicators?.lastsecuritycommitteenotice === 0) {
      return 'red';
    }
    return null;
  };
  return (
    <Layout
      title={t('choice_establishment.title_page')}
      queryError={
        getEstablishmentsListQuery?.error
      }
    >
      <header className={
        cn(['header',
          styles.headerChoice,
          'shadow-md',
          styles.security,
        ])
}
      >
        <h1 className="titleHomePage">
          <Picto name="etablissementsRoundedBlue" />
          {' '}
          {t('choice_establishment.title_page')}
          {' '}
          <Picto name="etablissementsRoundedBlue" />
        </h1>
      </header>
      <section className={styles.sectionChoice}>
        <div className={styles.searchRow}>
          <Search
            className="mb-20"
            value={search || ''}
            onChange={handleSearch}
          />
        </div>
        <div className={styles.filtersToComplete}>
          <div className={styles.list}>
            {avis?.map((building) => (
              <Button
                key={building.value}
                type="button"
                label={building.label}
                className={cn([styles.filter, buildingId === building.value
                  ? styles.active
                  : ''])}
                onClick={() => handleBuilding(building.value)}
              />
            ))}
          </div>
          <div className={styles.list}>
            <Button
              type="button"
              label={<FontAwesomeIcon icon={faTableCells} />}
              className={cn([styles.filter, type === 0
                ? styles.active
                : ''])}
              onClick={() => handleType(0)}
            />
            <Button
              type="button"
              label={<FontAwesomeIcon icon={faListUl} />}
              className={cn([styles.filter, type === 1
                ? styles.active
                : ''])}
              onClick={() => handleType(1)}
            />
          </div>
        </div>
        <div className={styles.backSectionList}>
          {establishmentsFiltered !== 2 && (
            <div className="loaderEtablissement" />
          )}
          {establishmentsFiltered === 2 && (
            <div className={type === 0 && styles.sectionChoiceList}>
              {type === 0 ? (
                establishments?.map((establishment) => (
                  <EstablishmentCardChoice
                    onClick={handleChoiceEstablishment}
                    establishment={establishment}
                    key={establishment?.id}
                    className={styles.cardEstab}
                    bookletHeaderColor={bookletHeaderColor(establishment)}
                    texte={(
                      <div className={styles.blockSecu}>
                        {establishment?.indicators?.lastsecuritycommitteedate && establishment?.indicators?.nextsecuritycommitteedate && (
                          <div>
                            {establishment?.indicators?.lastsecuritycommitteenotice === 1 ? (
                              <div className={styles.comisionSecu}>
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {' '}
                                    {t('choice_establishment.favorable')}
                                  </span>
                                  {' '}
                                  <span className={styles.favorable}>
                                    {formatDateToUser(establishment?.indicators?.lastsecuritycommitteedate?.date)}
                                  </span>
                                </div>
                                <li className={cn([styles.chrono_balls, styles.left_ball])}>{' '}</li>
                                <div className={styles.vertical} />
                                <li className={cn([styles.chrono_ball_prochaine, styles.right_ball])}>{' '}</li>
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {t('choice_establishment.next_commission')}
                                  </span>
                                  {' '}
                                  <span className={styles.values}>
                                    {formatDateToUser(establishment?.indicators?.nextsecuritycommitteedate?.date)}
                                  </span>
                                </div>
                              </div>
                            ) : null }
                            {establishment?.indicators?.lastsecuritycommitteenotice === 0 ? (
                              <div className={styles.comisionSecu}>
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {' '}
                                    {t('choice_establishment.defavorable')}
                                  </span>
                                  {' '}
                                  <span className={styles.defavorable}>
                                    {formatDateToUser(establishment?.indicators?.lastsecuritycommitteenotice)}
                                  </span>
                                </div>
                                <li className={cn([styles.chrono_ball, styles.left_ball])}>{' '}</li>
                                <div className={styles.verticalDefavorable} />
                                <li className={cn([styles.chrono_ball_prochaine, styles.right_ball])}>{' '}</li>
                                <div className={styles.comisionItem}>
                                  <span className={styles.label}>
                                    {t('choice_establishment.next_commission')}
                                  </span>
                                  {' '}
                                  <span className={styles.values}>
                                    {formatDateToUser(establishment?.indicators?.nextsecuritycommitteedate.date)}
                                  </span>
                                </div>
                              </div>
                            ) : null}
                            <hr className={styles.hr} />
                          </div>
                        )}
                        {establishment?.indicators?.totalsecurityobservationstoliftcount
                          || establishment?.indicators?.prescriptionssecuritytoliftcount
                          || establishment?.indicators?.securityrecordkeepingrate2
                          || establishment?.indicators?.latesecurityreportscount
                          || establishment?.indicators?.securityinputsdonepercentlastmonth
                          ? (
                            <div className={styles.container}>
                              {establishment?.indicators?.totalsecurityobservationstoliftcount !== null
                              || establishment?.indicators?.prescriptionssecuritytoliftcount !== null
                              || establishment?.indicators?.securityrecordkeepingrate2 !== null
                                ? (
                                  <div className={styles.texteCard}>
                                    <Picto name="contrats" className={styles.picto} />
                                    <div className={styles.CardTexts}>
                                      {establishment?.indicators?.totalsecurityobservationstoliftcount !== null ? (
                                        <li key={establishment.indicators?.totalsecurityobservationstoliftcount} className={styles.reporttext}>
                                          <span className={styles.value}>
                                            {establishment?.indicators?.totalsecurityobservationstoliftcount}
                                          </span>
                                          <span className={styles.reporttext}>
                                            {' '}
                                            {t('choice_establishment.totalObservationsToLiftCount')}
                                          </span>
                                        </li>
                                      ) : ''}
                                      {establishment?.indicators?.prescriptionssecuritytoliftcount !== null
                                        && establishment?.indicators?.prescriptionssecuritytoliftcount !== 0 ? (
                                          <li key={establishment.indicators?.prescriptionssecuritytoliftcount} className={styles.reporttext}>
                                            <span className={styles.value}>
                                              {establishment?.indicators?.prescriptionssecuritytoliftcount}
                                              {' '}
                                            </span>
                                            <span className={styles.reporttext}>
                                              {' '}
                                              {t('choice_establishment.prescriptionsToLiftCount')}
                                            </span>
                                          </li>
                                        ) : ''}
                                      {establishment?.indicators?.securityrecordkeepingrate2 !== null ? (
                                        <li key={establishment.indicators?.securityrecordkeepingrate2} className={styles.reporttext}>
                                          {establishment?.indicators?.securityrecordkeepingrate2 === 100 ? (
                                            <span className={cn([styles.value, 'tag--success'])}>
                                              {' '}
                                              {establishment?.indicators?.securityrecordkeepingrate2}
                                              {' '}
                                            </span>
                                          ) : null}
                                          {establishment?.indicators?.securityrecordkeepingrate2 < 100
                                            && establishment?.indicators?.securityrecordkeepingrate2 >= 70 ? (
                                              <span className={cn([styles.value, styles.tagWarning])}>
                                                {' '}
                                                {establishment?.indicators?.securityrecordkeepingrate2}
                                                {' '}
                                              </span>
                                            ) : null}
                                          {establishment?.indicators?.securityrecordkeepingrate2 < 70 ? (
                                            <span className={cn([styles.value])}>
                                              {' '}
                                              {establishment?.indicators?.securityrecordkeepingrate2}
                                              {' '}
                                            </span>
                                          ) : null}
                                          <span className={styles.reporttext}>
                                            {' '}
                                            {t('choice_establishment.recordKeepingRate2')}
                                          </span>
                                        </li>
                                      ) : ''}
                                    </div>
                                  </div>
                                ) : null}
                              {establishment?.indicators?.latesecurityreportscount !== null ? (
                                <div className={styles.texteCard}>
                                  <Picto name="depotrapport" className={styles.picto} />
                                  <div className={styles.CardTexts}>
                                    <li key={establishment.indicators?.latesecurityreportscount} className={styles.reporttext}>
                                      <span className={styles.value}>
                                        {' '}
                                        {establishment?.indicators?.latesecurityreportscount}
                                        {' '}
                                      </span>
                                      <span className={styles.reporttext}>
                                        {' '}
                                        {t('choice_establishment.latesecurityreportscount')}
                                      </span>
                                    </li>
                                  </div>
                                </div>
                              ) : null}
                              {establishment?.indicators?.securityinputsdonepercent !== null ? (
                                <div className={styles.texteCard}>
                                  <Picto name="inputs" className={styles.picto} />
                                  <div className={styles.CardTexts}>
                                    <li key={establishment.indicators?.securityinputsdonepercent} className={styles.reporttext}>
                                      <span className={styles.value}>
                                        {' '}
                                        {establishment?.indicators?.securityinputsdonepercent}
                                        {' '}
                                      </span>
                                      <span className={styles.reporttext}>
                                        {' '}
                                        {t('choice_establishment.securityinputsdonepercent')}
                                      </span>
                                    </li>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                      </div>
                    )}
                  />
                )) || ''
              ) : (
                <div className={styles.table}>
                  <Table
                    columns={Indicator}
                    isLoading={establishments?.isLoading}
                    data={establishments || []}
                    hasSort
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default ChoiceEstablishment;
